import { http } from '@/http'
import {
  EDadataType,
  EDadataTypeExtended,
  type IDadataAddress,
  type IDadataBank, type IDadataOrganization,
  type IFormPayload
} from '@/types/shared'
import { isEmptyObj, uuid } from '@/core/utils/shared'

class SurveyService {
  updateForm (_payload: IFormPayload) {
    return http.post('/form/', _payload)
  }

  async getFormDetail () {
    const result: IFormPayload = {}
    const response = await http.get<IFormPayload>('/form/detail/')
    Object.entries(response).forEach(([key, value]) => {
      if (isEmptyObj(value)) return
      result[key as keyof IFormPayload] = value
    })
    return result
  }

  async getDadataInfo <T extends EDadataType | EDadataTypeExtended> (query: string, type: T) {
    if (query === '') return []

    switch (type) {
    case EDadataType.ADDRESS:
      return (await http.post<IDadataAddress[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({ ...item, id: uuid() }))
    case EDadataType.BANK:
      return (await http.post<IDadataBank[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({
          ...item,
          value: `${item.value} (${item.inn}), ${item.address}`,
          name: `${item.value} (${item.inn})`,
          id: uuid()
        }))
    case EDadataType.ORGANIZATION:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({ ...item, id: uuid() }))
    case EDadataTypeExtended.ORGANIZATION_INN_ADDRESS:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type: EDadataType.ORGANIZATION }))
        .map((item) => ({
          ...item,
          value: `${item.value} (${item.inn}), ${item.address}`,
          name: `${item.value} (${item.inn})`,
          id: uuid()
        }))
    case EDadataTypeExtended.ORGANIZATION_ADDRESS:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type: EDadataType.ORGANIZATION }))
        .map((item) => ({
          ...item,
          value: `${item.value}, ${item.address}`,
          name: item.value,
          id: uuid()
        }))
    default:
      return []
    }
  }
}
export const surveyService = new SurveyService()
