import type { RouteRecordRaw } from 'vue-router'

const documentsRouteNames = {
  documents: 'documents'
}

const documentsRoutes: RouteRecordRaw = {
  path: 'documents',
  name: documentsRouteNames.documents,
  component: () => import('@/views/documents/Documents.vue')
}

export { documentsRouteNames, documentsRoutes }
