<template>
  <main>
    <ElConfigProvider :size="$componentSize.DEFAULT" :locale>
      <router-view />
    </ElConfigProvider>
  </main>
</template>

<script setup lang="ts">
import ru from 'element-plus/es/locale/lang/ru'

const locale = ru
</script>
