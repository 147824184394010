import { ESurveySteps, type IFormPayload, type TOnlyFillableSteps } from '@/types/shared'
import { payloadStepKeys, previousSurveyStep } from '@/constants'
import { cloneDeep } from 'lodash-es'

export function isFillableStep (step: ESurveySteps): step is TOnlyFillableSteps {
  return ![ESurveySteps.NO_ACTIVE_SURVEY, ESurveySteps.STEP_FINAL, ESurveySteps.HAS_FILLED_SURVEY].includes(step)
}

function getLastFilledStep (form: IFormPayload, step: ESurveySteps) {
  if (step === ESurveySteps.STEP_ONE) return ESurveySteps.NO_ACTIVE_SURVEY

  const prevStep = previousSurveyStep[step]
  if (!isFillableStep(prevStep)) {
    return ESurveySteps.NO_ACTIVE_SURVEY
  }
  if (form[payloadStepKeys[prevStep]]) {
    return step
  } else {
    return getLastFilledStep(form, prevStep)
  }
}

export function getActiveStep (form: IFormPayload) {
  const isMarried = form.step4?.is_in_marriage ?? true
  const isWidow = form.step4?.is_widow ?? true
  const lastStep = isMarried
    ? ESurveySteps.STEP_TWENTY_SIX
    : isWidow
      ? ESurveySteps.STEP_TWENTY
      : ESurveySteps.STEP_NINETEEN

  if (form[payloadStepKeys[lastStep]]) {
    return ESurveySteps.HAS_FILLED_SURVEY
  } else {
    return getLastFilledStep(form, lastStep)
  }
}

export function setDefaultFormValues <T extends Record<string, unknown>, K extends keyof T>
(_default: T, _model: T, _keys: K[] | 'all', _except: K[] = []) {
  const keys = _keys === 'all' ? Object.keys(_default) : _keys
  const set = new Set(_except)
  for (const key of keys) {
    if (!set.has(key as K)) { _model[key] = cloneDeep(_default[key]) }
  }
}
