import type { AxiosError, AxiosResponse } from 'axios'
import { useAuthStore } from '@/stores/shared'

const responseInterceptor = (response: AxiosResponse): Promise<AxiosResponse> => {
  return response.data
}

const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  const { logout } = useAuthStore()

  if (error.response?.status === 401) {
    logout()
  }

  return Promise.reject(error)
}

export {
  responseInterceptor,
  errorInterceptor
}
