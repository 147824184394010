import type { TNullable } from '@/types/shared'

export const enum EFileType {
  PDF = 'PDF',
  DOC = 'DOC'
}

export interface IMainInfo {
  id: number
  name: string
  email: string
  phone: string
  isk: TNullable<string>
  creditors: TNullable<string>
  properties: TNullable<string>
  generation_time: TNullable<string>
}

export interface IMainInfoDocument {
  name: string
  generationDate: string
  link: string
  extension: string
  fileType: EFileType
}
