import type { Plugin } from 'vue'
import * as Sentry from '@sentry/vue'

export const SentryPlugin: Plugin = {
  install: (app) => {
    Sentry.init({
      app,
      dsn: 'https://8557a72c7de7537f19f251dfa4e4ce22@o4507691890245632.ingest.de.sentry.io/4507696472391760',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false
        }),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration()
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/biglaw\.ru\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Profiling
      profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
    })
  }
}
