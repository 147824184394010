import { authRouteNames } from '@/views/auth/auth.routes'
import { mainRouteNames } from '@/views/main/main.routes'
import { surveyRouteNames } from '@/views/survey/survey.routes'
import { documentsRouteNames } from '@/views/documents/documents.routes'

export const routesNames = {
  rootPage: 'rootPage',
  notFound: 'notFound',
  ...authRouteNames,
  ...mainRouteNames,
  ...surveyRouteNames,
  ...documentsRouteNames
}
