import type { RouteRecordRaw } from 'vue-router'

const surveyRouteNames = {
  survey: 'survey'
}

const surveyRoutes: RouteRecordRaw = {
  path: 'survey',
  name: surveyRouteNames.survey,
  component: () => import('@/views/survey/Survey.vue')
}

export { surveyRouteNames, surveyRoutes }
