import { http } from '@/http'
import type { ILoginPayload, IRegisterUserPayload } from '@/types'

class AuthService {
  register (_payload: IRegisterUserPayload) {
    type TResponse = IRegisterUserPayload & { token: string }

    return http.post<TResponse>('/signup/', _payload)
  }

  login (_payload: ILoginPayload) {
    return http.post<{ token: string }>('/signin/', _payload)
  }

  loginAs (_token: string) {
    return http.post<{ token: string }>('/exchange_token/', { token: _token })
  }

  resetPasswordSendEmail (_username: string) {
    return http.post('/reset-password-mail/', { username: _username })
  }

  resetPasswordConfirm (_token: string) {
    return http.post<{password: string}>('/reset-password-finish/', { token: _token })
  }
}
export const authService = new AuthService()
