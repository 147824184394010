import type { ILoginPayload, IRegisterUserPayload } from '@/types'
import { authService } from '@/views/auth/auth.service'
import { localStorageKeys } from '@/constants'
import { routesNames } from '@/router'

export const useAuthStore = defineStore('authStore', () => {
  const router = useRouter()

  const token = ref(localStorage.getItem(localStorageKeys.AUTH_TOKEN) || '')

  function setToken (value: string) {
    token.value = value
    localStorage.setItem(localStorageKeys.AUTH_TOKEN, value)
  }

  async function registerUser (_payload: IRegisterUserPayload) {
    const res = await authService.register(_payload)

    setToken(res.token)

    await router.push({ name: routesNames.main })
  }

  async function loginUser (_payload: ILoginPayload | string) {
    let res: {token: string}
    if (typeof _payload === 'string') {
      res = await authService.loginAs(_payload)
    } else {
      res = await authService.login(_payload)
    }

    setToken(res.token)

    await router.push({ name: routesNames.main })
  }

  async function logout () {
    token.value = ''
    localStorage.removeItem(localStorageKeys.AUTH_TOKEN)

    await router.push({ name: routesNames.login })
  }

  async function resetPassword <T extends {email: string} | {token: string}> (_payload: T) {
    if ('email' in _payload) {
      await authService.resetPasswordSendEmail(_payload.email)
    } else {
      return (await authService.resetPasswordConfirm(_payload.token)).password
    }
  }

  return {
    token,
    loginUser,
    registerUser,
    resetPassword,
    logout
  }
})
