import type { RouteRecordRaw } from 'vue-router'

const authRouteNames = {
  auth: 'auth',
  login: 'login',
  register: 'register',
  resetPassword: 'resetPassword'
}

const authRoutes: RouteRecordRaw = {
  name: authRouteNames.auth,
  path: 'auth',
  redirect: { name: authRouteNames.login },
  children: [
    {
      name: authRouteNames.login,
      path: 'login',
      component: () => import('@/views/auth/Login.vue')
    },
    {
      name: authRouteNames.register,
      path: 'register',
      component: () => import('@/views/auth/Register.vue')
    },
    {
      name: authRouteNames.resetPassword,
      path: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue')
    }
  ]
}

export { authRouteNames, authRoutes }
