import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

import { errorInterceptor, responseInterceptor } from './response-interceptor'
import { requestErrorInterceptor, requestInterceptor } from './request-interceptor'

class HTTPService {
  readonly apiUrl: string
  private axios = {} as AxiosInstance

  constructor (apiUrl: string) {
    this.apiUrl = apiUrl
    this.createAxiosInstance()
    this.registerInterceptors()
  }

  parseUrl (url: string) {
    return url.includes('http') ? url : this.apiUrl + url
  }

  get<T> (url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(this.parseUrl(url), config)
  }

  put<T> (url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.put(this.parseUrl(url), payload, config)
  }

  post<T> (url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.post(this.parseUrl(url), payload, config)
  }

  patch<T> (url: string, payload: Record<string, any>, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.patch(this.parseUrl(url), payload, config)
  }

  delete<T> (url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.delete(this.parseUrl(url), config)
  }

  private createAxiosInstance () {
    this.axios = axios.create()
  }

  private registerInterceptors () {
    this.axios.interceptors.response.use(
      (res: AxiosResponse) => responseInterceptor(res),
      (err: AxiosError) => errorInterceptor(err)
    )

    this.axios.interceptors.request.use(requestInterceptor, requestErrorInterceptor)
  }
}

export const http = new HTTPService(import.meta.env.VITE_API_URL + '/api/v1/user')
