import { createApp } from 'vue'
import App from '@/App.vue'

import { createPinia } from 'pinia'
import { router } from '@/router'

import { VueGlobalPropertiesPlugin, SentryPlugin } from '@/plugins'

import '@/assets/styles/main.scss'

const app = createApp(App)

app
  .use(createPinia())
  .use(router)
  .use(VueGlobalPropertiesPlugin)
  .use(SentryPlugin)

router.isReady().then(() => {
  app.mount('#app')
})
