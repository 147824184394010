type TObject = Record<string, unknown>

type TGetPropertyType<Obj extends TObject, Key extends string> = Key extends `${infer Head}.${infer Rest}`
  ? Head extends keyof Obj
    ? Obj[Head] extends TObject
      ? TGetPropertyType<Obj[Head], Rest>
      : never
    : never
  : Key extends keyof Obj
    ? Obj[Key]
    : never;

type TNestedKeys<Obj extends TObject> = {
  [Key in keyof Obj]: Key extends string
    ? Obj[Key] extends TObject
      ? `${Key}` | `${Key}.${TNestedKeys<Obj[Key]>}`
      : `${Key}`
    : never
}[keyof Obj];

/**
 * Type Safe function used to get property value of object
 * @param obj - Object, keys should be string
 * @param key - Key in string format, can be separated by dots for nested values like "prop1.prop2.prop3"
 */
export function getValue<T extends TObject, K extends TNestedKeys<T>> (obj: T, key: K): TGetPropertyType<T, K> {
  return key.split('.').reduce((acc: any, part) => acc && acc[part], obj) as TGetPropertyType<T, K>
}

export function isEmptyObj (obj: object) {
  return Object.keys(obj).length === 0
}
