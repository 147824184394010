import type { RouteRecordRaw } from 'vue-router'

const mainRouteNames = {
  main: 'main'
}

const mainRoutes: RouteRecordRaw = {
  path: 'main',
  name: mainRouteNames.main,
  component: () => import('@/views/main/Main.vue')
}

export { mainRouteNames, mainRoutes }
