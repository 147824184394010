import type { Plugin } from 'vue'
import {
  EComponentType,
  EComponentSize,
  EPopoverPlacement,
  EFormLabelPlacement
} from '@/types/shared'
import { routesNames } from '@/router'

export const VueGlobalPropertiesPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.$componentType = EComponentType
    app.config.globalProperties.$componentSize = EComponentSize
    app.config.globalProperties.$popoverPlacement = EPopoverPlacement
    app.config.globalProperties.$formLabelPlacement = EFormLabelPlacement
    app.config.globalProperties.$routesNames = routesNames
  }
}
