import type { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { useAuthStore } from '@/stores/shared'

interface IAdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

const requestInterceptor = (requestConfig: IAdaptAxiosRequestConfig): IAdaptAxiosRequestConfig => {
  const authStore = useAuthStore()

  if (requestConfig.headers && authStore.token) {
    requestConfig.headers.Authorization = `Token ${authStore.token}`
  }

  return requestConfig
}

const requestErrorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

export {
  requestInterceptor,
  requestErrorInterceptor
}
