import { routesNames } from '@/router/route-names'

import type { NavigationGuard } from 'vue-router'
import { useAuthStore } from '@/stores/shared'

export const routeGuard: NavigationGuard = async (
  to,
  _,
  next) => {
  const authStore = useAuthStore()

  const isAuthenticated = !!authStore.token
  const isAuthRoute = to.name === routesNames.login ||
    to.name === routesNames.register ||
    to.name === routesNames.resetPassword

  if (isAuthenticated) {
    if (isAuthRoute && !to.query.token) {
      next({ name: routesNames.main })
    } else {
      next()
    }
  } else {
    if (isAuthRoute) {
      next()
    } else {
      next({ name: routesNames.login })
    }
  }
}
